import React from 'react'
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'

import Layout from 'components/Layout'

type LegalDocumentWebviewTemplateProps = {
  pageContext: {
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
  }
}

export default function LegalDocumentWebviewTemplate({
  pageContext: { content },
}: LegalDocumentWebviewTemplateProps) {
  return (
    <Layout className="legal-document-page" webview>
      <div className="container py-4">{renderRichText(content)}</div>
    </Layout>
  )
}
